<template>
    <div class="mt-0">
        <banner v-if="bannerOn"></banner>
        <navigation :banner-on="bannerOn"></navigation>
        <div class="container-spacing">
            <slot></slot>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import Navigation from '@nextgenmath/js/components/Navigation.vue';
    import Foot from '@nextgenmath/js/components/Foot.vue';
    import Banner from '../components/Banner.vue';

    export default {
        data: () => ({
            showBanner: true,
        }),
        computed: {
            bannerOn() {
                return this.showBanner && (!this.computedUser || this.computedUser && this.computedUser.role != 'student')
            },
            computedUser() {
                if (this.$page) 
                    return this.$page.props.user;
                
                return this.user;
            },
        },
        components: {
            Banner,
            Navigation,
            Foot,
        }
    }
</script>

<style lang="scss">

.container-spacing {
    padding-bottom: 1em;
    margin-top: 5rem; // announcement banner on

    @media (min-width: 992px) {	
        // margin-top: 5rem; // announcement banner off
        margin-top: 8rem; // announcement banner on
    }
}
</style>
